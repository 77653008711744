import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ManutencaoRegistroCadastro } from '../../../model/acoes/atendimento/manutencao-registro-cadastro';
import { Observable } from 'rxjs';
import { ManutencaoRegistroConsulta } from '../../../model/acoes/atendimento/manutencao-registro-consulta';
import { ManutencaoRegistroAlteracao } from '../../../model/acoes/atendimento/manutencao-registro-alteracao';

@Injectable({
  providedIn: 'root'
})
export class ManutencaoRegistroService {

  urlApi: string = `${environment.url}/manutencao-registro`;

  constructor(
    private http: HttpClient
  ) { }

  public apontarOs(formData: any): Observable<ManutencaoRegistroConsulta> {
    return this.http.post<ManutencaoRegistroConsulta>(`${this.urlApi}`, formData);
  }

  public alterarApontamento(apontamento: ManutencaoRegistroAlteracao): Observable<ManutencaoRegistroConsulta> {
    return this.http.put<ManutencaoRegistroConsulta>(`${this.urlApi}`, apontamento);
  }

  /**
   * Consulta todos os apontamentos de um ordem de servico
   * @param idOs
   * @returns 
   */
  public consultarApontamentosPorIdOs(idOs: number): Observable<ManutencaoRegistroConsulta[]> {
    return this.http.get<ManutencaoRegistroConsulta[]>(`${this.urlApi}/${idOs}`);
  }

   public excluirApontamento(idOs: number): Observable<any> {
    return this.http.delete<any>(`${this.urlApi}/${idOs}`);
  }

}
