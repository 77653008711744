import { Equipamento } from "../equipamento/manutencao-equipamento";

export class OrdemDeServicoConsulta {
    id!: number;
    equipamento: Equipamento = new Equipamento();
    solicitacao!: string;
    solicitante!: string;
    prioridade!: string;
    stopProd!: string;
    tipo!: string;
    dataCadastro!: string;
    aprovador!: string | null;
    obsAprovador!: string | null;
    dataAlteracao!: string | null;
    dataFim!: string | null;
    tempoExecucao!: string | null;
    status!: string;
    justificativa!: string;
    imagens:any[] =[];
}
