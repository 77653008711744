import { ManutencaoChecklist } from "./manutencao-checklist";
import { ManutencaoComponenteCritico } from "./manutencao-componente-critico";
import { ManutencaoItemEquipamento } from "./manutencao-item-equipamento";
import { ManutencaoTPM } from "./manutencao-tpm";

export class Equipamento {
    id!: number;
    codigo!: string;
    serie!: string;
    descricao!: string;
    funcao!: string;
    potencia!: string;
    capacidade!: string;
    instrucaoManutencao!: string;
    frequencia!: string;
    local!: string;
    preventiva!: string;
    obs!: string;
    status!: string;
    merge!: string;
    usuarioCadastro!: string;
    dataCadastro!: Date;
    dataAlteracao!: Date;
    usuarioAlteracao!: string;
    dataProxima!: string;
    itens!: ManutencaoItemEquipamento[];
    tpm!: ManutencaoTPM[];
    checklist!: ManutencaoChecklist[];
    proximaPreventiva!:string;
    componentesCriticos!: ManutencaoComponenteCritico[];
  }
  